<template>
   <div>
      <v-data-table :headers="columns" :items="filteredRecords" dense
         :items-per-page="15" :footer-props="{'items-per-page-options': [15, 30, 60]}" :hide-default-footer="!records.length"
         >
         <template v-slot:top>
            <v-toolbar flat>
               <v-text-field v-model="table.search" prepend-inner-icon="mdi-magnify" placeholder="Search" hide-details
                  class="mr-3 hidden-sm-and-down" style="max-width: 240px;" flat single-line clearable
                  />
               <v-spacer />
               <v-btn v-if="$hasRole(location.kind.toLowerCase(), 'E')" x-large icon tile color="accent" class="mx-1" @click="downloadXLS">
                  <v-icon size="44">
                     mdi-database-export
                  </v-icon>
               </v-btn>
               <v-btn v-if="$hasRole(location.kind.toLowerCase(), 'I')" x-large icon tile color="accent" class="mx-1" :loading="$store.state.process === 'importLocation'" @click="$refs.xlsxImport.click()">
                  <v-icon size="44">
                     mdi-database-import
                  </v-icon>
                  <input ref="xlsxImport" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" alt="xlsxImport" style="display:none;" @change="uploadXLS">
               </v-btn>
               <v-btn v-if="$hasRole(location.kind.toLowerCase(), 'C')" x-large icon tile color="secondary" class="mx-1" @click="location.id = 0">
                  <v-icon size="44">
                     mdi-map-marker-plus
                  </v-icon>
               </v-btn>
            </v-toolbar>
            <v-divider />
         </template>
         <template v-slot:[`item.building`]="{ item }">
            {{ buildingText(item.building) }}
         </template>
         <template v-slot:[`item.View`]="{ item }">
            <v-btn v-if="item.View" icon small tile>
               <v-avatar tile width="30" height="22" @click="downloadView(item)">
                  <img :src="setUrlFromImage(item.View)" alt="View" style="object-fit: cover;" @error="() => { item.View = '' }">
               </v-avatar>
            </v-btn>
            <v-icon v-else size="30" class="my-n1">
               mdi-image-area
            </v-icon>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="$hasRole(item.kind.toLowerCase(), 'RU')" class="ml-2" @click="modifyRecord(item)">
               {{ $hasRole(item.kind.toLowerCase(), 'U') ? 'mdi-pencil' : 'mdi-eye' }}
            </v-icon>
            <v-icon v-if="$hasRole(item.kind.toLowerCase(), 'D')" class="ml-2" @click="removeRecord(item)">
               mdi-delete
            </v-icon>
         </template>
         <template v-slot:no-data>
            <div class="my-5" style="font-size: 1.15rem;">
               No data available
            </div>
         </template>
      </v-data-table>
      <v-dialog v-model="dialog.del" max-width="330px">
         <v-card>
            <v-list-item two-line class="grey lighten-2 px-6 py-2">
               <v-list-item-content dark>
                  <v-list-item-title>{{ `Delete item ${record.name}?` }}</v-list-item-title>
                  <v-list-item-subtitle>{{ record.identity }}</v-list-item-subtitle>
               </v-list-item-content>
            </v-list-item>
            <v-card-actions class="px-6 py-4">
               <v-spacer />
               <v-btn color="grey darken-2" text class="px-4" @click="dialog.del = false">
                  Cancel
               </v-btn>
               <v-btn color="secondary" depressed class="px-4" @click="deleteRecord">
                  OK
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import { imageFromUrl, pastel } from '@/utils/nirtara'
import { floor } from '@/utils/fields.js'
export default {
   name: 'NFloorTable',

   props: {
      location: { type: Object },
      records: { type: Array },
      buildings: { type: Array },
      imports: { type: Function },
   },

   data: () => ({
      isUsed: false,
      columns: [
         { text: 'Code', value: 'identity', width: 115 },
         { text: 'Name', value: 'name' },
         { text: 'Number', value: 'access', width: 120 },
         { text: 'Building', value: 'building' },
         { text: 'View', value: 'View', width: 30, align: 'center pr-0', sortable: false },
         { text: 'Actions', value: 'actions', width: 80, align: 'end pl-0', sortable: false },
      ],
      dialog: { del: false },
      table: { search: '', filter: [] },
      record: {},
   }),

   computed: {
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
      filteredRecords () {
         return this.records.filter((j) => {
            const search = this.table.search?.toLowerCase()
            var filteredName = j.name && j.name.toLowerCase().indexOf(search) !== -1
            return (!search || filteredName) &&
                   (!this.table.filter.length || this.table.filter.includes(j.floor))
         })
      },
      buildingText () {
         return building => (this.buildings.find(j => j.identity === building) || { name: building }).name
      },
   },
   created () {
   },
   methods: {
      uploadXLS () {
         const reader = new FileReader()
         reader.onload = async e => {
            const workbook = await new this.$excelJS.Workbook().xlsx.load(reader.result)
            const rows = []
            workbook.eachSheet(worksheet => {
               if (worksheet.name !== 'Floor') return
               worksheet.eachRow((row, idx) => {
                  if (idx === 1) return
                  const object = {
                     [worksheet.name]: [],
                     name: String(row.values[2] || ''),
                     building: String(row.values[3] || ''),
                  }
                  const array = []
                  row.values[1] && row.values[2] && rows.push({
                     kind: worksheet.name,
                     identity: String(row.values[1] || ''),
                     array: array,
                     object: object,
                  })
               })
            })
            // console.log(`uploadXLS (${rows.length}):`, JSON.parse(JSON.stringify(rows)))
            this.imports(rows, len => {
               this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${len} floors imported successfully` }], kind: 'Success' })
            })
         }
         reader.readAsArrayBuffer(this.$refs[event.target.alt].files[0])
      },
      async downloadXLS () {
         const workbook = new this.$excelJS.Workbook()
         workbook.creator = 'NIRTARA Visitor Management'
         const worksheet = workbook.addWorksheet(this.location.kind)

         const column = { identity: 'Code', name: 'Name', building: 'Building' }
         worksheet.columns = Object.keys(column).map(s => ({ header: column[s], key: s }))
         worksheet.getRow(1).eachCell(cell => { cell.style = { font: { bold: true, size: 12 } } })
         let keys = []
         this.filteredRecords.map(j => { worksheet.addRow(j); keys.push(j.building) })

         this.$excelJS.autoWidth(worksheet)

         keys = keys.filter((value, index, self) => self.indexOf(value) === index)
         const background = pastel(1 + keys.length)
         const rules = keys.map((t, i) => ({
            type: 'expression',
            formulae: [`$C2="${t}"`],
            style: { fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: background[i] } } },
         }))
         worksheet.addConditionalFormatting({ ref: `A2:${this.$excelJS.numberToLetters(Object.keys(column).length - 1)}${1 + this.filteredRecords.length}`, rules: rules })

         const saveAs = require('file-saver')
         const buffer = await workbook.xlsx.writeBuffer()
         const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
         saveAs(blob, `NVM-50-WEB-${this.$moment().format('YYYYMMDD-HHmmss')}.xlsx`)
      },

      downloadView (item) {
         // const saveAs = require('file-saver')
         // saveAs(imageFromUrl(item.View), `${item.identity}-${this.$moment().format('YYYYMMDD-HHmmss')}`)
      },
      modifyRecord (item) {
         Object.assign(this.location, floor, item)
         this.$vuetify.goTo(0)
      },
      removeRecord (item) {
         this.record = Object.assign({}, { id: -1 }, item)
         this.dialog.del = true
      },
      deleteRecord () {
         this.$emit('delete', this.record)
         this.dialog.del = false
      },
   },
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table-header > tr > th span {
   font-size: 1.0rem;
   letter-spacing: 0;
   min-height: 60px;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 0.9rem;
}
::v-deep .v-data-footer,
::v-deep .v-data-footer__select .v-select__selections .v-select__selection--comma {
   font-size: 0.9rem;
}
// ::v-deep .v-list-item__action:first-child {
//    margin-right: 16px;
// }
</style>
